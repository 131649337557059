<template>
<div :class="{locatorpage: locatorpage}" class="locator">
    <div class="search">
        <h1 class="text-center" v-if="locatorpage">{{displayTitle}}</h1>
        <h4 class="text-center sub sm" v-else>{{displayTitle}}</h4>
        <section class="search-form">
            <form @submit.prevent="search" class="form">
                <div class="inputs">
                    <input type="text" placeholder="Zip Code" id="zip-input" v-model="zipcode" minlength="5" maxlength="5"/>
                    <div class="custom-select">
                        <label for="distance" v-if="!distance">Miles</label>
                        <select v-model="distance" name="distance" id="distance">
                            <option disabled="disabled">Miles</option>
                            <option value="5">5mi</option>
                            <option value="10">10mi</option>
                            <option value="25">25mi</option>
                            <option value="50">50mi</option>
                            <option value="100">100mi</option>
                        </select>
                    </div>
                </div>
                <div class="btn full">
                    <button @click.prevent="search" ref="searchBtn">Submit</button>
                </div>
            </form>
            <span class="or">OR</span>
            <div class="btn full geo">
                <button @click.prevent="geolocate" ref="geo">
                    Use Current Location
                </button>
            </div>
        </section>
    </div>
    <div class="locator-results" v-if="markers.length">
        <div class="themap">
            <GmapMap
                :center="center"
                :zoom="10"
                :options="mapStyle"
                style="width:100%;  height: 400px;"
                ref="mapRef">
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    @click="toggleInfoWindow(m)"
                    :icon="'/images/svg/map-marker.svg'">
                </GmapMarker>
                <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                    <h4>{{currentMarker.Name}}</h4>
                    <address>
                        {{currentMarker.Address}}<br/>
                        {{currentMarker.City}} {{currentMarker.State}}, {{currentMarker.Zip}}
                    </address>
                    <a v-bind:href="googleLink(currentMarker)" target="_blank">{{currentMarker.Distance}}mi - Get Directions</a>
                </GmapInfoWindow>
            </GmapMap>
        </div>
        <div class="locations">
            <ul>
                <li v-for="(m, index) in markers" :key="index">
                    <div class="container">
                        <h4 class="sub">{{m.Name}}</h4>
                        <address>
                            {{m.Address}}<br/>
                            {{m.City}} {{m.State}}, {{m.Zip}}
                        </address>
                        <span>{{m.Distance}}mi</span>
                        <div class="btn-container">
                            <div class="btn">
                                <a v-bind:href="googleLink(m)" target="_blank">Get Directions</a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="online-retailers" v-show="onlineRetailers.length">
        <h2>Find it Online</h2>
        <div class="search-form">
            <div class="form">
                <div class="custom-select">
                    <select v-model="selectedOnlineRetailer" name="online-retailers" aria-label="Select an Online Retailer">
                        <option value="" disabled>Select Online Store</option>
                        <option v-for="(m, index) in onlineRetailers" :key="index" :value="m.Link">{{m.Name}}</option>
                    </select>
                </div>
                <div class="btn full">
                    <button @click.prevent="shopOnline">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
    props:['title', 'locatorpage', 'productid'],
    data() {
        return {
            // default to Montreal to keep it simple
            // change this to whatever makes sense
            center: { lat: 45.508, lng: -73.587 },
            distance:0,
            markers: [],
            zipcode: '',
            currentMarker: {},
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            onlineRetailers: [],
            selectedOnlineRetailer:'',
            foundProduct:0,
            lat:false,
            long:false,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            mapStyle:{
                styles: [{featureType:"all",elementType:"labels.text",stylers:[{color:"#878787"}]},{featureType:"all",elementType:"labels.text.stroke",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f9f5ed"}]},{featureType:"road.highway",elementType:"all",stylers:[{color:"#f5f5f5"}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#c9c9c9"}]},{featureType:"water",elementType:"all",stylers:[{color:"#aee0f4"}]}]
            }
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const distance = urlParams.get('distance');
        if(distance){
            this.distance = distance;
        }

        const zip = urlParams.get('zip');
        if(zip){
            this.zipcode = zip;
            this.search();
        }
    },
    methods: {
        resetButtons(){
            this.$refs.searchBtn.disabled = false;
            this.$refs.geo.disabled = false;
            this.$refs.searchBtn.innerText = 'Submit';
            this.$refs.geo.innerText = 'Use Current Location';
        },
        async getLocations(url){
            if(this.productid){
                url += this.productid + '/';
            }

            this.markers = [];

            await this.$gmapApiPromiseLazy();

            try{
                if(!this.distance){
                    this.distance = 25;
                }
                fetch(url+'?distance='+this.distance).then(response => response.json()).then(response => {
                    if(response.status && response.locations && response.locations.length){
                        let bounds  = new this.google.maps.LatLngBounds();
                        for(let i = 0; i < response.locations.length; i++){
                            response.locations[i].position = {
                                lat: parseFloat(response.locations[i].Latitude),
                                lng: parseFloat(response.locations[i].Longitude)
                            };
                            const loc = new this.google.maps.LatLng(response.locations[i].position.lat, response.locations[i].position.lng);
                            bounds.extend(loc);
                        }
                        this.markers = response.locations;
                        this.foundProduct = response.found;
                        this.$nextTick(() => {
                            this.$refs.mapRef.$mapPromise.then((map) => {
                                map.fitBounds(bounds);
                                map.panToBounds(bounds);
                            });
                        });
                    } else {
                        alert('Sorry we were not able to find any locations for this zip code, please try again.');
                    }
                    if(!this.onlineRetailers.length){
                        fetch('/cw/onlineretailers/').then(response => response.json()).then(response => {
                            if(response.status && response.retailers && response.retailers.length){
                                this.onlineRetailers = response.retailers;
                            }
                        });
                    }
                    this.resetButtons();
                });

            } catch(e){
                console.log(e);
                alert('Sorry we were not able to find any locations for this zip code, please try again.');
                this.resetButtons();
            }
        },
        search() {
            if(this.zipcode && this.zipcode.length == 5){
                this.$refs.searchBtn.disabled = true;
                this.$refs.geo.disabled = true;
                this.$refs.searchBtn.innerText = 'Searching';

                let url = '/cw/locator/'+this.zipcode+'/';
                this.getLocations(url);
            }
        },
        googleLink(marker){
            return 'https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(marker.Address+' '+marker.City+' '+marker.State+' '+marker.Zip);
        },
        toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.currentMarker = marker;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }

            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        shopOnline(){
            if(this.selectedOnlineRetailer){
                window.open(this.selectedOnlineRetailer);
            }
        },
        geolocate(){
            this.zipcode = '';
            this.$refs.searchBtn.disabled = true;
            this.$refs.geo.disabled = true;
            this.$refs.geo.innerText = 'Searching';
            navigator.geolocation.getCurrentPosition(position => {
                //console.log(position);
                let url = '/cw/locator/'+position.coords.latitude+','+position.coords.longitude;
                this.getLocations(url);
            }, () => {
                alert('To search by current location, you must allow the browser to locate your current location.');
            });
        }
    },
    computed: {
        google: gmapApi,
        displayTitle(){
            if(this.title){
                return this.title;
            }

            return 'Where To Buy';
        }
    }
};
</script>

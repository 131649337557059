import Plyr from 'plyr';

let videos = [];
var divisor = 25;
// We're going to save our players status on this object.
var videos_status = {};
function eventHandler(e){
    switch(e.type) {
        case 'timeupdate':
            // Let's set the save the current player's video time in our status object
            videos_status[e.target.id].current = Math.round(e.target.currentTime);
            // We just want to send the percent events once
            var pct = Math.floor(100 * videos_status[e.target.id].current / e.target.duration);
            for (var j in videos_status[e.target.id]._progress_markers) {
                if (pct >= j && j > videos_status[e.target.id].greatest_marker) {
                    videos_status[e.target.id].greatest_marker = j;
                }
            }
            // current bucket hasn't been already sent to GA?, let's push it to GTM
            if (videos_status[e.target.id].greatest_marker && !videos_status[e.target.id]._progress_markers[videos_status[e.target.id].greatest_marker]) {
                videos_status[e.target.id]._progress_markers[videos_status[e.target.id].greatest_marker] = true;
                window.gtag('event', 'video_progress', {
                    'video_url':window.location.href,
                    'video_duration':e.target.duration,
                    'video_current_time':e.target.currentTime,
                    'video_title':decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
                    'video_percent':videos_status[e.target.id].greatest_marker
                });
            }
            break;
            // This event is fired when user's click on the play button
        case 'play':
            window.gtag('event', 'video_start', {
                'video_url':window.location.href,
                'video_duration':e.target.duration,
                'video_current_time':e.target.currentTime,
                'video_title':decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
                'video_percent':videos_status[e.target.id].greatest_marker
            });
            break;
        case 'ended':
            window.gtag('event', 'video_complete', {
                'video_url':window.location.href,
                'video_duration':e.target.duration,
                'video_current_time':e.target.currentTime,
                'video_title':decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
                'video_percent':'100'
            });
            break;
        default:
            break;
    }
}

const initVideos = () => {
    document.querySelectorAll('.video-js').forEach(v => {
        const player = new Plyr(v, {fullscreen:{enabled:true, fallback:true, iosNative:true, container:null}});
        videos.push(player);
        if(window.gtag){
            if(!v.getAttribute('id')){
                v.setAttribute('id', 'html5_video_' + Math.random().toString(36).slice(2));
            }
            videos_status[v.getAttribute('id')] = {
                greatest_marker:0,
                _progress_markers: {},
                current:0
            };
            for (let j = 0; j < 100; j++) {
                videos_status[v.getAttribute('id')].progress_point = divisor * Math.floor(j / divisor);
                videos_status[v.getAttribute('id')]._progress_markers[videos_status[v.getAttribute('id')].progress_point] = false;
            }
            v.addEventListener('play', eventHandler, false);
            v.addEventListener('timeupdate', eventHandler, false);
            v.addEventListener('ended', eventHandler, false);
        }
    });
};

const killVideos = () => {
    videos.forEach(player => {
        player.destroy();
    });
    document.querySelectorAll('video').forEach(vid => {
        vid.removeEventListener('play');
        vid.removeEventListener('timeupdate');
        vid.removeEventListener('ended');
    })
}

export { initVideos, killVideos };

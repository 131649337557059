<script>
export default {
    replace:false,
    data () {
        return {
            toggled:false,
            scrolled:false
        }
    },
    methods: {
        toggleNav () {
            this.toggled = !this.toggled
        },
        closeNav () {
            this.toggled = false;
        },
        setupScroll () {
            window.addEventListener('scroll', this.handleScroll, {passive:true});
        },
        setupResize () {
            window.addEventListener('resize', this.handleResize, {passive:true});
        },
        handleScroll () {
            if(window.pageYOffset > 20){
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
        handleResize () {
            if(window.outerWidth >= 960){
                this.closeNav()
            }
        }
    },
    mounted () {
        this.setupScroll();
        this.setupResize();
        this.$refs.header.querySelectorAll('a').forEach(a => {
            a.addEventListener('click', this.closeNav)
        })
    }
}
</script>

<template>
    <dialog id="agegate-dialog" ref="dialog">
        <div class="container">
            <form>
                <img src="/images/logo.svg" alt="Macanudo Logo" class="logo" width="212" height="135" />
                <h1>Are you 21+?</h1>
                <div class="Actions">
                    <div class="btn full">
                        <button @click.prevent="authorize">Yes</button>
                    </div>
                    <div class="btn full">
                        <button @click.prevent="deny">No</button>
                    </div>
                </div>
                <h3 v-if="denied" class="error">Sorry, you must be 21+ to&nbsp;enter</h3>
                <img src="https://www.cigarworld.com/assets/Uploads/SGW.png" alt="Surgeon General's Warning" class="sgw" width="252" height="63"/>
                <p class="legal">
                    By clicking enter, I accept the <a href="/terms-of-use/">Terms of Use</a> and
                    <a href="/privacy-policy/">Privacy Policy</a>.
                </p>
            </form>
        </div>
    </dialog>
</template>
<script>
import Cookies from 'js-cookie'
import dialogPolyfill from 'dialog-polyfill';

export default {
    data () {
        return {
            denied:false
        }
    },
    methods: {
        authorize () {
            this.denied = false
            Cookies.set('ofage', true, { expires: 7, path: '/' })
            window.scrollTo(0, 0)
            this.$refs.dialog.close();
            window.macInit();
        },
        deny () {
            this.denied = true;
        }
    },
    mounted () {
        dialogPolyfill.registerDialog(this.$refs.dialog);
        if(!Cookies.get('ofage')){
            this.$refs.dialog.addEventListener('cancel', (event) => {
                event.preventDefault();
            });
            this.$refs.dialog.showModal();
        }
    }
}
</script>

<script>
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

export default {
    replace:false,
    computed:{
        startPosition(){
            if(window.outerHeight < 1000){
                return "top bottom-=100px";
            }
            return "top center+=250px";
        }
    },
    mounted(){
        let childSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-child'});
        let parentSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-parent'});

        let tween = gsap.from(childSplit.lines, {
            duration:0.8, yPercent:100, ease:"power4", stagger:0.1,
            scrollTrigger:{
                trigger:this.$refs.h,
                start:this.startPosition,
                once:true
            },
            onComplete(){
                tween.kill();
            }
        });

        childSplit.lines.forEach(l => {
            l.style.removeProperty('text-align');
        });
        parentSplit.lines.forEach(l => {
            l.style.removeProperty('text-align');
        });
    }
}
</script>

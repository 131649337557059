<script>
export default {
    replace:false,
    data () {
        return {
            email:false,
            nickname:false
        }
    },
    methods:{
        submit(e){

        }
    }
}
</script>

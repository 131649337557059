import { gsap } from "gsap";

const globalAnimations = () => {

}

const homeAnimation = (tl) => {

}

const productsAnimation = (tl) => {

}

const productAnimation = (tl) => {

}

const aboutAnimation = (tl) => {

}

const locatorAnimation = (tl) => {

}

const loaderIn = (container) => {
    const tl = gsap.timeline();
    tl.to(container, {
        duration: 0.5,
        autoAlpha: 0,
        ease: 'Power4.out'
    });
    return tl;
}

const loaderAway = (next) => {
    const tl = gsap.timeline();
    tl.from(next.container, {
        duration: 0.5,
        autoAlpha: 0,
        ease: 'Power4.out'
    });

    // globalAnimations();

    // if(next.namespace == 'home'){
    //     homeAnimation(tl);
    // } else if(next.namespace == 'products'){
    //     productsAnimation(tl);
    // } else if(next.namespace == 'product'){
    //     productAnimation(tl);
    // } else if(next.namespace == 'about'){
    //     aboutAnimation(tl);
    // } else if(next.namespace == 'locator'){
    //     locatorAnimation(tl);
    // } else {
    //     return tl;
    // }
}

export { loaderIn, loaderAway, globalAnimations };

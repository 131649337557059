<script>
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
//import { ScrollTrigger } from 'gsap/ScrollTrigger';
//import isSafari from '../../components/isSafari';

export default {
    replace:false,
    props:['evenodd'],
    data(){
        return {

        }
    },
    computed:{
        imageScrollTrigger(){
            return {
                trigger:this.$refs.image.parentElement,
                start:"top center",
                once:true
            }
        },
        startPosition(){
            if(window.outerHeight < 1000){
                return "top bottom-=100px";
            }
            return "top center+=250px";
        },
        imageAnimation(){
            if(window.outerWidth < 768){
                return {
                    duration:0.6, scale: 0.8, opacity: 0
                }
            } else if(this.evenodd == 'odd'){
                return {
                    duration:0.6, opacity: 0, x:'50%'
                }
            } else {
                return {
                    duration:0.6, opacity: 0, x:'-50%'
                }
            }

        }
    },
    mounted(){
        let childSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-child'});
        let parentSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-parent'});

        let imageTl = gsap.timeline({
            scrollTrigger:this.imageScrollTrigger
        });

        imageTl.from(this.$refs.image.querySelector('img'), this.imageAnimation);

        let tl = gsap.timeline({
            scrollTrigger:{
                trigger:this.$refs.h.parentElement,
                start:this.startPosition,
                once:true
            },
            onComplete(){
                tl.kill()
            }
        });
        if(this.$refs.logo){
            tl.from(this.$refs.logo.querySelector('img'), {
                duration:0.3, opacity: 0, yPercent:50, scale:0.8
            }).from(childSplit.lines, {
                duration:0.8, yPercent:100, ease:"power4", stagger:0.1
            }).from(this.$refs.copy, {
                opacity:0,
                duration:0.5
            }, 0.5);
        } else {
            tl.from(childSplit.lines, {
                duration:0.8, yPercent:100, ease:"power4", stagger:0.1
            }).from(this.$refs.copy, {
                opacity:0,
                duration:0.5
            }, 0.3);
        }

        childSplit.lines.forEach(l => {
            l.style.removeProperty('text-align');
        });
        parentSplit.lines.forEach(l => {
            l.style.removeProperty('text-align');
        });
    }
}
</script>
